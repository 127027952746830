import { api, removeAuthToken } from './api'
import { parseCookies, setCookie } from 'nookies'

import { USER_TOKEN } from 'lib/constants'
import { APIDefaultError } from 'types/api'
import type {
  SignInResponseData, SignInRequestData, SignUpRequestData, ResetPasswordRequestData, SignUpResponseData, LoggedUserResponseData, ForgotPasswordRequestData, ChangePasswordRequestData
} from 'types/auth'

export async function signInRequest(data: SignInRequestData): Promise<SignInResponseData> {
  return api.post<SignInRequestData, SignInResponseData>('/auth/login', data)
    .then((data) => Promise.resolve(data))
    .catch(({ response }) => Promise.reject(response.data as APIDefaultError))
}

export async function getUserInfo(): Promise<LoggedUserResponseData> {
  try {
    const { data } = await api.get('/auth/me')
    return Promise.resolve(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function signUpRequest(data: SignUpRequestData): Promise<SignUpResponseData> {
  return api.post<SignUpRequestData, SignUpResponseData>('/auth/register', data)
    .then((data) => Promise.resolve(data))
    .catch(({ response }) => Promise.reject(response.data as APIDefaultError))
}

export async function forgotPasswordRequest(data: ForgotPasswordRequestData) {
  return api.post('/auth/forgot-password', data)
    .then(data => Promise.resolve(data))
    .catch(({ response }) => Promise.reject(response.data as APIDefaultError))
}

export async function resetPasswordRequest(data: ResetPasswordRequestData) {
  return api.post('/auth/reset-password', data)
    .then(data => Promise.resolve(data))
    .catch(({ response }) => Promise.reject(response.data as APIDefaultError))
}

export async function changePasswordRequest(data: ChangePasswordRequestData) {
  return api.post('/auth/change-password', data)
    .then(data => Promise.resolve(data))
    .catch(({ response }) => Promise.reject(response.data as APIDefaultError))
}

export async function consultConfirmEmailRequest(token: string) {
  return api.get(`/auth/email-verification/${token}`)
    .then(data => Promise.resolve(data))
    .catch(({ response }) => Promise.reject(response.data as APIDefaultError))
}

export async function confirmEmailRequest(token: string) {
  return api.put(`/auth/email-verification/${token}/confirm`)
    .then(data => Promise.resolve(data))
    .catch(({ response }) => Promise.reject(response.data as APIDefaultError))
}

export async function resendEmailRequest(email: string) {
  return api.post(`/auth/email-verification/${email}/send`)
    .then(data => Promise.resolve(data))
    .catch(({ response }) => Promise.reject(response.data as APIDefaultError))
}

export function signOut() {
  console.debug('>>>>>> signing out <<<<<<')
  setCookie(null, USER_TOKEN, '', {
    path: '/',
    maxAge: 0
  })
  removeAuthToken()
}

export function getAuthToken() {
  const { [USER_TOKEN]: token } = parseCookies()
  return token
}

export const keepSignedIn = (access_token: string) => {
  const daysInMs = 60 * 60 * 24
  const signInCookieAge = daysInMs * 7
  setCookie(null, USER_TOKEN, access_token, {
    path: '/',
    maxAge: signInCookieAge
  })
}
